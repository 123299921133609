.video-show {
    width: 250px;
    height: 400px;
    border: 1px solid #eeeeee;
    border-radius: 6px;
}

.video-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
}

.video-box video {
    width: 100%;
    height: 100%;
}

.video-pop-close {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
}

.video-pop-close button {
    width: 100px;
}