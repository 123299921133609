.QRCode {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 250px;
    padding-right: 15px;
    margin-top: 10px;
}

.QRCode img {
    width: 250px;
    height: 250px;
    border: 1px solid #e0e0e0;
    border-radius: 5px !important;
}

.QRCode span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    font-size: 20px;
    color: #bdbdbd;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.QRCode-tit {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    padding: 15px;
}

.data-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.data-box .sum {
    display: flex;
    align-items: center;
    width: calc(50% - 30px);
    margin: 15px;
}

.data-box .sum span:first-child {
    display: flex;
    width: 65px;
}

.data-box .sum span:last-child {
    display: flex;
    width: calc(100% - 65px);
}

.video-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 250px;
    padding-right: 15px;
    margin-bottom: 15px;
}

.video-list>.video-list-tit {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    padding: 10px 10px 10px 60px;
}

.video-list>.video-list-body {
    /* display: block;
    align-items: center; */
    width: 760px;
    margin-left: 60px;
    height: calc(100% - 34px);
    white-space: nowrap;
    overflow-x: auto;
}

.video-list>.video-list-body .video-card {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: calc(100% - 14px);
    margin-right: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
}

.video-list>.video-list-body .video-card .close {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.video-list>.video-list-body .video-card img {
    width: 200px;
    height: 200px;
    margin-right: 10px;
}

.video-list>.video-list-body .video-card span {
    width: 200px;
    height: 200px;
    font-size: 20px;
    color: #bdbdbd;
}

.video-list>.video-list-body .video-card video {
    width: 200px;
    height: 100%;
    object-fit: cover;
}

.video-list .video-tip {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #b1b0b0;
    font-size: 20px;
}
.videoPublishTypeItem{
    margin-top: -20px;
    display:flex;
    align-items: center;
    padding: 0 46px;
}
.videoPublishTypeItemLabel{
    width: 80px;
    text-align: right;
}
.iptItem{
    margin: 20px 0 0 4px;
}
.actitem input{

    width: 200px;
}

.actitem textarea{
    width: 500px;
    height: 100px;
}

.actitem-bigline{
    width: 500px;
}

.actitem-bigline input{
    width: 500px;

}
.uploading-span{
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.uploading-fail-span{
    width: 100px;
    height: 100px;

}

.ddlktaskmaterialvideo{
    width: 100px;
    height: 100px;
    background-color: #e1e1e1;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;

}


.progress{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 100;
    text-align: center;
    padding-top: 100px;
}

.bkm-btn-fill{
    background: #2D49FE;
    color: white;
    border:none;
    border-radius: 6px;
    padding: 5px 8px;
}


.overlay-full{
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 100;
    padding: 10px;
}

.topic-list{
    margin: 10px 0px;
    overflow: scroll;
    height: calc(100% - 50px);
}

.topic-list > div{
    padding: 6px;
}

.text-danger{
    color:#FF1E1E;
}