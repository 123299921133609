.form-container .form-item.flex .ant-form-item-control-input-content,
.form-container .form-item.flex-time .ant-form-item-control-input-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.form-container .form-item.flex .ant-form-item-control-input-content .ant-form-item {
    margin-left: 10px;
    width: calc((100% - 20px) / 3);
}

.form-container .form-item.flex .ant-form-item-control-input-content .ant-form-item:first-child {
    margin-left: 0px;
}

.form-container .form-item.flex .ant-form-item-control-input-content .ant-form-item:last-child {
    margin-left: 0px;
    margin-bottom: 0;
}

.form-container .form-item.flex-time .ant-form-item-control-input-content .ant-form-item {
    width: calc((100% - 10px) / 2);
}

.form-container .form-item.flex-time .ant-form-item-control-input-content .ant-form-item:first-child {
    margin-right: 10px;
}

.logo-tip {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b1b0b0;
    width: 104px;
    height: 104px;
    border: 1px dashed #d9d9d9;
    margin: 0 10px 10px 0;
}

.form-img.img .ant-upload-picture-card-wrapper {
    width: auto;
}

.form-img.img .ant-form-item-control-input-content {
    justify-content: flex-start;
    align-items: center;
}

.form-img.img .ant-form-item-control-input-content .ant-image {
    margin-bottom: 10px;
    margin-right: 10px;
    border: 1px dashed #d9d9d9;
}

.company-tip {
    padding: 2px 4px;
    color: #ffffff;
    border-radius: 3px;
    margin-right: 5px;
}

.company-tip.blue {
    background: #03a9f4;
}

.company-tip.orange {
    background: #ff9800;
}

.form-radio {
    display: inline-flex;
    padding-right: 10px;
    width: 100%;
}

.form-radio .ant-radio-group {
    display: inline-flex;
}

.form-radio .ant-radio-group .ant-radio-wrapper:last-child {
    margin-right: 0;
}

.form-radio .ant-radio-group .ant-radio-wrapper span:last-child {
    padding: 5px;
}
.materialWrap{
    width: 100%;
    height: 100%;
    min-height: 500px;
    padding: 20px;
    overflow-y: scroll;
}
.materialItem{
    width: 200px;
    height: 120px;
    margin: 0 20px 20px 0;
}
.materialItemUploading{
    background-color: red;
    width: 200px;
    height: 120px;
    margin: 0 20px 20px 0;
}
.materialItemBox{
    position: relative;
}
.materialItemWrap{
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    white-space: normal;
}
.selectedMaterial{
    position: absolute;
    top:10px;
    left:10px;
}

.addresstextara{
    width: 500px;
}