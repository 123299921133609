html,
body {
    width: 100%;
    height: 100%;
    font-size: 12px;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.c-red {
    color: #f44336 !important;
}

.wrapper {
    width: 100%;
    height: 100%;
}

.head {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    background: #ffffff;
    min-width: 960px;
    z-index: 1;
}

.head-title {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 100%;
    color: #ffffff;
    font-size: 18px;
    background: #03a9f4;
}

.head-title .logo {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 22px;
    background: url(../assets/images/logo.png) no-repeat center;
    background-size: 101px 42px;
    background-position: 15px center;
    padding-left: 125px;
}

.head-stretch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 5px;
    color: #03a9f4;
    font-size: 24px;
    /* background: #f2f2f2; */
    border-radius: 5px;
    cursor: pointer;
}

.head-nav {
    display: inline-flex;
    width: calc(100% - 430px);
    height: 100%;
    margin: 0 10px;
    overflow: hidden;
}

.head-nav span {
    position: relative;
    display: inline-flex;
    align-items: center;
    color: #333333;
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
}

.head-nav span.active {
    color: #03a9f4;
}

.head-user {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* width: 140px; */
    height: 100%;
    cursor: pointer;
}

.head-user>.user-img {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
}

.head-user>.user-img>span {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../assets/images/users.png) no-repeat center center;
    background-size: 100% 100%;
}

.head-user>.user-img>img {
    width: 100%;
    height: 100%;
}

.head-user>.user-name {
    display: flex;
    align-items: center;
    color: #424242;
    font-size: 14px;
}

.head-user>.icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20px;
    color: #424242;
    margin: 0 15px 0 5px;
}

.head-user-info {
    position: absolute;
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 5px;
    box-shadow: 0 0 5px #d8d8d8;
    box-sizing: border-box;
}

.head-user-info>span {
    display: flex;
    align-items: center;
}

.head-user-info>span>span {
    margin-right: 5px
}

.head-user-info.active {
    display: block;
}

.head-user-info>span {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5px 0;
    cursor: pointer;
}

.nav {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 240px;
    height: 100%;
    padding: 55px 0 0 0;
    background: #000000;
    transition: all 0.3s ease;
}

.nav .nav-ul {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.nav .nav-ul .nav-li {
    width: 100%;
    height: 40px;
    color: #ffffff;
    background: #2ca9f4;
    border-radius: 5px;
    cursor: pointer;
}

.nav.active {
    margin-left: -240px;
    transition: all 0.3s ease;
}

.nav .ant-menu {
    background: none;
    color: #ffffff;
}

.nav .ant-menu-inline {
    border: none;
}

.nav .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #333333;
}

.nav .ant-menu-item:hover,
.nav .ant-menu-item-active,
.nav .ant-menu-submenu-selected,
.nav .ant-menu-submenu-title:hover,
.nav .ant-menu-item-selected {
    color: #03a9f4;
}

.ant-menu-inline .ant-menu-item::after {
    border-right: 4px solid #03a9f4;
}

.nav .ant-menu-item-group-title {
    color: #bdbdbd;
}

.section {
    display: block;
    margin-left: 240px;
    margin-right: 0;
    margin-top: 0;
    height: 100%;
    min-height: 100%;
    /* padding: 65px 10px 10px 10px; */
    padding: 70px 15px 15px 15px;
    background: #eee;
    transition: all 0.3s ease;
    box-sizing: border-box;
}

.section.active {
    margin-left: 0;
    transition: all 0.3s ease;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.query-criteria {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 5px 10px 5px 0;
    border-bottom: 15px solid #eeeeee;
}

.query-criteria .input-criteria {
    padding-left: 10px;
    margin: 5px 0;
}

.table {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 10px;
}

.table .ant-table-wrapper {
    width: 100%;
    height: 100%;
    border: 1px solid #f0f0f0;
}

.table .ant-table-pagination.ant-pagination {
    margin: 10px 10px 0 0;
}

.tab-container {
    flex: 1 1;
}

.table-btn {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    min-height: 32px;
}

.table-btn .ant-radio-group {
    position: absolute;
    left: 0;
}

.table-btn button {
    margin-left: 10px;
}

.ant-table-content {
    overflow: auto;
}

.ant-table-body {
    border-bottom: 1px solid #f2f2f2;
    overflow: auto;
}


/* 搜索 */

.search-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 5px 10px 5px 0;
    background: #ffffff;
    margin-bottom: 15px;
    border-radius: 5px;
}

.search-item {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 25%;
    padding: 10px 0 0 15px;
    margin-bottom: 10px;
}

.search-item label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 115px;
}

.ant-form-horizontal .ant-form-item-control {
    width: calc(100% - 115px);
}

.search-item .ant-input-affix-wrapper {
    flex: 1;
}

.search-item .ant-picker {
    width: 100%;
}

.search-btn {
    display: flex;
    justify-content: flex-end;
    width: 25%;
    padding: 10px 0 0 15px;
    margin-bottom: 10px;
}

.search-btn button {
    margin-left: 10px;
}

.min-label .ant-form-item-label label,
.search-item.min-label label {
    min-width: 60px;
}

.table-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 10px;
    border-radius: 5px;
    background: #ffffff;
}


/* form表单样式 */

.from-modal .ant-modal-body {
    padding: 10px 5px 5px 20px;
}

.form-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 100px;
    max-height: 500px;
    background: #ffffff;
    border-radius: 5px;
    overflow-y: auto;
}

.form-container .form-content {
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
}

.form-container .del-border {
    border: none;
    pointer-events: none;
}

.form-container .form-titlt {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    background: #f5f5f5;
    border-radius: 5px;
}

.form-container .form-item {
    display: inline-flex;
    padding-right: 10px;
    width: 100%;
}

.form-container .form-item img {
    width: 100px;
}

.form-container .form-radio .ant-form-item-label label,
.form-container .form-item label {
    display: inline-flex;
    justify-content: flex-end;
    min-width: 130px;
    text-align: right;
}

.form-container .form-radio.min-label .ant-form-item-label label,
.form-container .form-item.min-label label {
    min-width: 60px;
}

.form-container .ant-picker,
.form-container .form-item .ant-input-number {
    width: 100%;
}

.form-container .ant-input[disabled],
.form-container .ant-input-number-disabled,
.form-container .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.form-container .ant-select-disabled.ant-select-multiple .ant-select-selector,
.form-container .ant-picker.ant-picker-disabled,
.form-container .ant-input-affix-wrapper-disabled {
    background: #fafafa;
}

.form-container .form-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 10px 10px 10px;
    margin-bottom: 0;
}

.form-container .form-btn button {
    margin-left: 10px;
}

.form-container .form-item .item-tip {
    display: flex;
    width: 100%;
    color: #c6c6c6;
    margin-top: -20px;
}

.from-modal {
    max-height: 600px;
}

.form-container .form-item.two .ant-form-item-control-input-content {
    display: flex;
}

.form-container .form-item.two {
    margin-bottom: 0;
}

.form-container .form-item.two .ant-form-item-control-input-content .ant-form-item {
    width: 50%;
}

.form-container .two-input {
    width: calc(100% - 4px);
}

.form-container .two-line {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 32px;
    padding-right: 5px;
}

.table-col-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #03a9f4;
}

.table-col-btn span {
    display: flex;
    align-items: center;
    padding: 0 5px;
    border-right: 1px solid #03a9f4;
    word-wrap: normal;
    height: 16px;
    cursor: pointer;
}

.table-col-btn span:last-child {
    border-right: none;
}

.ant-scrolling-effect {
    width: 100% !important;
}

.form-img .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab-container .ant-table-pagination.ant-pagination {
    margin-bottom: 1px;
}

.form-item.resetPassword .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width: 800px) and (max-width:1280px) {
    .search-item,
    .search-btn {
        width: 50%;
        max-width: 50%;
        flex: none;
    }
}

@media screen and (min-width: 0px) and (max-width:800px) {
    .search-item,
    .search-btn {
        width: 100%;
        max-width: 100% !important;
        flex: none;
    }
}

@media (max-width: 575px) {
    .section {
        overflow-y: auto;
    }
    .ant-form-item .ant-form-item-label>label {
        justify-content: flex-start;
    }
}

::-webkit-scrollbar {
    width: 5px !important;
    height: 5px;
    cursor: pointer;
}

::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
}